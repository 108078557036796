import { Card } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import Image from '../../atoms/Image/Image'
import Title from '../../atoms/Title/Title'
import Button from '../../../Button/Button'
import React, { useState } from 'react'
import { useStyles } from './ModalItremStyles.js'
import { Alert, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import { Box } from '@mui/system'
import AuxButton from '../../atoms/Button/Button'
import { ENVIRONMENT } from '../../../../settings.js'
import { useSelector } from 'react-redux'
import TitleWithTooltip from '../../atoms/TitleWithTooltip/TitleWithTooltip.js'

const ModalItrem = ({
  open,
  onClose,
  type,
  icon,
  onConfirm,
  onCancel,
  message,
  loanType,
  settings = {}
}) => {
  const classes = useStyles()
  const handleClose = () => {
    onClose(false)
  }

  const { whiteList, user } = useSelector((state) => state.whiteList)

  let withTooltip = false;
  let tooltipMessage = '';

  if(loanType === 'secure-unsecure' && type !== 'single'){
    withTooltip = true;
    tooltipMessage = '* Cash security refers to using cash as a form of guarantee for 80% of your loan.'
  }

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={open}
      aria-labelledby='draggable-dialog-title'
      style={{ borderRadius: '0px' }}
      onClose={handleClose}
    >
      <Card style={{ width: 'auto', height: 'auto', borderRadius: '0px' }}>
        <CardContent className={classes.CardContent}>
          <Box className={classes.AlertMainSection}>
            <Image className={classes.CrossImage} src={icon} height={type === 'big' ? '50px' : '70px'} />
            {withTooltip ? (
            <TitleWithTooltip
              fontSize={type === 'big' ? '23px' : '14px'}
              fontWeight={type === 'big' ? '400' : '700'}
              className={classes.AlertText}
              Text={message}
              tooltipMessage={tooltipMessage}
              color={type === 'big' ? '#0F5CAC' : '#A3A3A3'}
            />
          ) : (
            <Title
              fontSize={type === 'big' ? '23px' : '14px'}
              fontWeight={type === 'big' ? '400' : '700'}
              className={classes.AlertText}
              Text={message}
              color={type === 'big' ? '#0F5CAC' : '#A3A3A3'}
            />
          )}
            {type === 'single' && (
              <Button
                className={classes.ConfirmButton}
                Title='Back'
                variant='subtitle1'
                BtnVariant='contained'
                onClick={onConfirm}
                color='primary'
              />
            )}
            {type === 'double' && (
              <>
              {[ENVIRONMENT.PRE_PRODUCTION].includes(settings.environment) && whiteList.includes(user.partyId) && (
                <Button
                  className={classes.ConfirmButtonDouble}
                  Title='YES'
                  variant='subtitle1'
                  BtnVariant='contained'
                  onClick={() => onConfirm()}
                  color='primary'
                />
              )}
              {[ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT].includes(settings.environment) && (
                <Button
                  className={classes.ConfirmButtonDouble}
                  Title='YES'
                  variant='subtitle1'
                  BtnVariant='contained'
                  onClick={() => onConfirm()}
                  color='primary'
                />
              )}
              {[ENVIRONMENT.PRE_PRODUCTION].includes(settings.environment) && whiteList.includes(user.partyId) && (
                <Button
                  className={classes.CancelButton}
                  Title={'NO'}
                  variant='subtitle1'
                  BtnVariant='contained'
                  onClick={() => onCancel()}
                  color='primary'
                />
              )}
              {[ENVIRONMENT.PRE_PRODUCTION].includes(settings.environment) && !whiteList.includes(user.partyId) && (
                <Button
                  className={classes.CancelButton}
                  Title={'CONTINUE'}
                  variant='subtitle1'
                  BtnVariant='contained'
                  onClick={() => onCancel()}
                  color='primary'
                />
              )}
              {[ENVIRONMENT.PRODUCTION, ENVIRONMENT.DEVELOPMENT].includes(settings.environment) && (
                <Button
                  className={classes.CancelButton}
                  Title={'NO'}
                  variant='subtitle1'
                  BtnVariant='contained'
                  onClick={() => onCancel()}
                  color='primary'
                />
              )}
              </>
            )}
            {type === 'big' && (
              <Button
                className={classes.ConfirmButton}
                Title='OK'
                variant='subtitle1'
                BtnVariant='contained'
                onClick={() => onConfirm()}
                color='primary'
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Dialog>
  )
}

export default ModalItrem
