import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  BtnContainer:{
    '&.MuiGrid-root':{
      "@media (max-width: 600px)": {
        maxWidth: '100%'
      },
    }
  },
  isDownloaded:{
    "&.MuiButtonBase-root": {
      height: "39px",
      // width: '414px',
      borderRadius: "7px",
      backgroundColor: '#fff',
      marginTop: "16px",
      width: '300px',
      border: '1px solid #56AF31',
      color: '#56AF31',
      "&:hover": {
        // backgroundColor: '#F4EBFE !important',
        // color: theme.colors.primary.Darkblue
        backgroundColor: '#56AF31 !important',
        color: '#fff'
      },
      "& .MuiTypography-root": {
        fontSize: "13px",
        fontFamily: theme.fonts.family.primary,
        fontWeight: 400,
        letterSpacing: "0.2em",
        
      },
      "@media (max-width: 1100px)": {
       // margin: "0",
      },
      "@media (max-width: 600px)": {
        // width: "100%",
        maxWidth: '240px',
        height: 'auto'
        //marginBottom: 20,
      },
    },    
  },
  mobileHeader: {
    '&.MuiGrid-root': {
    '@media (max-width: 900px)': {
      maxWidth: '100%',
      flexBasis: '100%'
    },
  },
  },
  CustomTitle: {
    fontFamily: theme.fonts.family.secondary,
    fontWeight: "900",
    color: "#0f5cac",
    "@media (max-width: 600px)": {
      fontSize: "14px !important",
      fontWeight: "900",
      // lineHeight: "27.32px",
      // letterSpacing: "0.1em",
    },
  },
  PopupContainer: {
    width: "467.05px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EDEDED",
    filter: "drop-shadow(4px 4px 3px rgba(91, 91, 91, 0.349))",
    borderRadius: "10px",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  PopupHeader: {
    height: "62.92px",
    background: " #56AF31",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  PopupHeaderLogo: {
    height: "111.14px",
    width: "111.14px",
    backgroundColor: "#ffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    position: "absolute",
    top: "-70px",
    filter: " drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  Logo: {
    height: "90px",
    width: "90px",
  },
  PopupMainSection: {
    width: "433.07px",
    position: "relative",
    background: "#FFFFFF",
    margin: "20px auto",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
      width: "95%",
    },
  },
  PopupText: {
    "&.MuiTypography-root": {
      textAlign: "center",
      fontFamily: theme.fonts.family.secondary,
      fontSize: "16px",
      lineHeight: "21.86px",
      fontWeight: "300",
      margin: "14.53px 0",
      color: "#706F6F",
      paddingTop: "50px",
      paddingLeft: "19.53px",
      paddingRight: "16.54px",
      FontFamily: "Avenir",
    },
  },
  PopupExpireTime: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "34.55px",
    marginBottom: "23.91px",
    gap: "6.23px",
  },
  ClockImage: {
    width: "24px",
    height: "24px",
  },
  ExpireTimeText: {
    "&.MuiTypography-root": {
      fontSize: "16px",
      fontWeight: "500",
      color: "#005FAA",
      fontFamily: theme.fonts.family.secondary,
    },
  },
  PopupBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0px",
    width: "180px",
    height: "39.01px",
    background: "#005FAA",
    marginBottom: "39px",
  },
  PopupButton: {
    "&.MuiButton-root": {
      color: "#ffff",
      fontSize: "13px",
      lineHeight: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  AlertBoxContainer: {
    "& .MuiDialog-paper": {
      width: "468px",
      borderRadius: "00px",
      marginTop: "100px",
      marginRight: "48px",
      borderTopLeftRadius: "0px",
      borderTopRightRadius: "0px",
      "@media (max-width: 600px)": {
        width: "368px",
      },
    },
  },
  AlertBoxHeader: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    height: "55.04px",
    backgroundColor: "#ffffff",
    filter: "drop-shadow(4px 4px 3px rgba(91, 91, 91, 0.349))",
  },
  AlertMainSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  HeaderLogoContainer: {
    position: "relative",
    width: "111.14px",
    height: "111.14px",
    backgroundColor: "#ffff",
    top: "-42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  HeaderLogoImage: {
    width: "90.02px",
    height: "90.02px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  CrossImage: {
    width: "90px",
  },

  AlertText: {
    "&.MuiTypography-root": {
      fontSize: "15.87px",
      fontWeight: "500",
      lineHeight: "22px",
      letterSpacing: "0.1em",
      fontFamily: theme.fonts.family.secondary,
      color: "#A3A3A3",
      marginBottom: "20px",
    },
    "@media (max-width: 600px)": {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
  AlertBtn: {
    "&.MuiButton-root": {
      width: "176.01px",
      height: "39.01px",
      backgroundColor: theme.colors.secondary.Green,
      margin: " 9px",
      color: "white",
      fontFamily: theme.fonts.family.primary,
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "3px",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "@media (max-width: 460px)": {
        // width: "auto",
      },
    },
    "@media (max-width: 600px)": {
      
    },
  },

  // seccess Section//

  SuccessBoxContainer: {
    width: "469.2px",
    height: "220.19px",
    position: "relative",
    margin: "40px auto",
    borderRadius: "10px",
    boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.25)",
  },

  SuccessBoxHeader: {
    width: "469.2px",
    height: "55.04px",
    backgroundColor: "#F07F3C",
    position: "relative",
  },
  SuccessMainSection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  SuccessLogoContainer: {
    position: "relative",
    width: "111.14px",
    height: "111.14px",
    backgroundColor: "#ffff",
    top: "-44px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  SuccessLogoImage: {
    width: "90.02px",
    height: "90.02px",
    borderRadius: "50%",
    backgroundColor: "#F07F3C",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  SuccessText: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      fontWeight: "800",
      lineHeight: "27.32px",
      letterSpacing: "0.1em",
      fontFamily: theme.fonts.family.secondary,
      color: "#706F6F",
    },
  },
  SuccessDiscription: {
    maxWidth: "85%",
    height: "118.83px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#EAF2FB",
    margin: "30px auto",
    padding: "0px 20px",
  },

  SuccessDiscriptionText: {
    "&.MuiTypography-root": {
      fontSize: "20px",
      lineHeight: "27.32px",
      letterSpacing: "0.024em",
      textAlign: "center",
      color: "#706F6F",
      fontFamily: theme.fonts.family.tertiary,
    },
  },
  ConfirmButton: {
    "&.MuiButtonBase-root": {
      height: "39px",
      // width: '414px',
      borderRadius: "7px",
      backgroundColor: theme.colors.primary.Darkblue,
      marginTop: "16px",
      width: '300px',
      "&:hover": {
        // backgroundColor: '#F4EBFE !important',
        // color: theme.colors.primary.Darkblue
        backgroundColor: '#53AF32 !important',
        color: theme.palette.common.white
      },
      "& .MuiTypography-root": {
        fontSize: "13px",
        fontFamily: theme.fonts.family.primary,
        fontWeight: 400,
        letterSpacing: "0.2em",
      },
      "@media (max-width: 1100px)": {
       // margin: "0",
      },
      "@media (max-width: 600px)": {
        width: "100%",
        minWidth: '240px',
        height: 'auto'
        //marginBottom: 20,
      },
    },
  },
  CancelButton: {
    "&.MuiButtonBase-root": {
      height: "39px",
      width: '414px',
      border: `2px solid ${theme.palette.primary.dark}`,
      color: theme.colors.primary.Darkblue,
      backgroundColor: theme.palette.common.white,
      borderRadius: "7px",
      marginTop: "16px",
      "&:hover": {
        backgroundColor: '#53AF32 !important',
        color: theme.palette.common.white,
        border: "0px",
      },
      "& .MuiTypography-root": {
        fontSize: "13px",
        fontFamily: theme.fonts.family.primary,
        fontWeight: 400,
        letterSpacing: "0.2em",
      },
      "@media (max-width: 1100px)": {
        //margin: "0",
      },
      "@media (max-width: 600px)": {
        width: "90%",
        //marginBottom: 20,
      },
    },
  },
  CardContent: {
    height: "228px",
    display: "flex", 
    alignItems: "flex-end", 
    justifyContent: "center",
    "@media (max-width: 600px)": {
      width: "368px",
      //marginBottom: 20,
    },
  },
  separator: {
    margin: '0 8px', // Espacio horizontal entre los textos y el punto grande
    fontSize: '24px', // Tamaño del punto grande
  },
  Title: {
    marginBottom: "0px"
  }
}));
