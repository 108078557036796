import React, { Fragment, useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import { Stack } from "@mui/system";
import Input from "../../Inputs/InputWithAdornment";
import Select from "../../Inputs/SelectWithAbsolute";
import Button from "../../Button/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useStyles } from "./MonthlyCreditObligationsStyle";
import { LabelList } from "./MonthlyCreditLabel";
import { FieldArray, Field, Form } from "formik";
import { template } from "./validation";
import { InputAdornment, TextField } from "@mui/material";
import Currency from "../../Inputs/currency2";
import ClearIcon from '@mui/icons-material/Clear';
import { Interceptor } from "../../../Utils/Interceptor";
import { baseUrl } from "../../../baseUrl"
const MonthlyCreditData = ({ formik }) => {
	const AxiosInterceptor = Interceptor();

	const classes = useStyles();
	const { handleBlur } = formik;
	const loanPaymnentFileRef = useRef([]);
	const morgageOrRentFileRef = useRef([]);
	const creditCardFileRef = useRef([]);
	const overDraftFileRef = useRef([]);
	const otherCreditObligationFileRef = useRef([]);

	const [arrayFieldAmount, setArrayFieldAmount] = useState({
		loanPayments: 0,
		morageOrRent: 0,
		creditCard: 0,
		overDraft: 0,
		other: 0,
		rent: 0,
	});
	const loanPaymnentFileRefClick = (index, e) => {
		loanPaymnentFileRef.current[index].click();
	};
	const morgageOrRentFileRefClick = (index, e) => {
		morgageOrRentFileRef.current[index].click();
	};
	const creditCardRefClick = (index, e) => {
		creditCardFileRef.current[index].click();
	};
	const overDraftFileRefClick = (index, e) => {
		overDraftFileRef.current[index].click();
	};
	const otherCreditObligationFileRefClick = (index, e) => {
		otherCreditObligationFileRef.current[index].click();
	};
	const removeLoanPayments = async (index) => {
		const data = formik.values.loanPayments[index];
		if (data.id != '') {
			await AxiosInterceptor.delete(
				`/monthly-credit-obligations/${data.id}`
			)
		}

	}
	const removemorageOrRent = async (index) => {
		const data = formik.values.morageOrRent[index];
		if (data.id != '') {
			await AxiosInterceptor.delete(
				`/monthly-credit-obligations/${data.id}`
			)
		}

	}
	const removecreditCard = async (index) => {
		const data = formik.values.creditCard[index];
		if (data.id != '') {
			await AxiosInterceptor.delete(
				`/monthly-credit-obligations/${data.id}`
			)

		}

	}
	const removeoverDraft = async (index) => {
		const data = formik.values.overDraft[index];
		if (data.id != '') {
			await AxiosInterceptor.delete(
				`/monthly-credit-obligations/${data.id}`
			)

		}

	}
	const removeother = async (index) => {
		const data = formik.values.other[index];
		if (data.id != '') {
			await AxiosInterceptor.delete(
				`/monthly-credit-obligations/${data.id}`
			)

		}

	}
	const renderFieldArray = (label) => {

		switch (parseInt(label.id)) {
			case 1:
				return (
					<FieldArray
						key={`loanPayments_${label.id}`}
						name="loanPayments"
						render={(arrayHelpers) => (
							<div style={{padding: '10px 10px 60px 10px', backgroundColor: '#f5f4f4'}}>
								{formik.values.loanPayments?.map((field, index) => (
									<Stack key={index} mt={4}>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center"
											mb={{ md: 4 }}
										>
											<Field name={`loanPayments.${index}.amount`}>
												{({ field, form, meta }) => (
													<>
														<Currency
															Lable={label.label}
															className={classes.MonthlyCreditInput}
															error={meta.touched && meta.error}
															onChange={(event, value) => {
																formik.setFieldTouched(field.name)
																formik.setFieldValue(field.name, value)
															}}
															value={meta.value}

														/>
													</>
												)}
											</Field>
											{index == 0 ? (
												<AddCircleIcon
													className={classes.PlusIcon}
													onClick={() => {
														if (arrayFieldAmount.loanPayments < 4) {
															arrayHelpers.push(template);
															setArrayFieldAmount((prevData) => {
																return {
																	...prevData,
																	loanPayments: prevData.loanPayments + 1,
																};
															});
														}
													}}
												/>
											) : (
												<HighlightOffIcon
													className={classes.RemoveIcon}
													onClick={() => {
														removeLoanPayments(index);
														arrayHelpers.remove(index);
														setArrayFieldAmount((prevData) => {
															return {
																...prevData,
																loanPayments: prevData.loanPayments - 1,
															};
														});
													}}
												/>
											)}
										</Stack>

										<Stack mt={4}>
											<Field name={`loanPayments.${index}.bankId`}>
												{({ field, form, meta }) => (
													<>
														<Select
															{...field}
															Lable="Bank"
															placeholder="Select financial institution"
															ApiUrl={`${baseUrl}/banks?filter={"order":"position"}`}
															error={meta.touched || meta.error}
															value={meta.value}
															onBlur={() => {
																formik.setFieldTouched(field.name);
															}}
															onChange={(e, value, reason) => {
																if (reason == "clear")
																	form.setFieldValue(field.name, "");
																else {
																	value &&
																		form.setFieldValue(field.name, value);
																}
															}}
														/>
													</>
												)}
											</Field>
										</Stack>

										<Stack direction="column" width={{ xs: "100%", sm: "49%" }} mt={2}>
											<Button
												Title="Upload Files"
												variant="h7"
												className={classes.UploadFiles}
												endIcon={<AttachFileIcon />}
												onClick={(e) => loanPaymnentFileRefClick(index, e)}
											/>
											<Field name={`loanPayments.${index}.file`}>
												{({ field, form, meta }) => (
													<>
														<input
															{...field}
															type="file"
															accept="application/pdf,image/*"
															onChange={(event) => {
																formik.setFieldValue(
																	`loanPayments.${index}.file`,
																	event.currentTarget.files[0]
																);
															}}
															className={classes.MonthlyCreditInput}
															value=""
															style={{ display: "none" }}
															ref={(el) =>
																(loanPaymnentFileRef.current[index] = el)
															}
														/>
														<p className={classes.fileText}>
															Upload your loan statement from the financial
															institution selected. Statements retrieved from
															online banking are accepted.
														</p>
														{meta.error ? (
															<p
																className={classes.fileText}
																style={{ color: "red", marginTop: 0 }}
															>
																{meta.error}
															</p>
														) : (
															meta?.value?.name && (
																<p className={classes.uploadFileName}>
																	{`${String(meta.value.name).substring(0, 20)} ...`}
																	<span>
																		<ClearIcon className={classes.deleteFileIcon}
																			onClick={() => {
																				formik.setFieldValue(
																					field.name,
																					null
																				)
																			}} />
																	</span>
																</p>
															)
														)}
													</>
												)}
											</Field>
										</Stack>
									</Stack>
								))}
							</div>
						)}
					/>
				);
			case 2:
				return (
					<FieldArray
						key={`morageOrRent${label.id}`}
						name="morageOrRent"
						render={(arrayHelpers) => (
							<Fragment>
								{formik.values.morageOrRent?.map((field, index) => (
									<Stack key={index} mt={4}>

										<Stack
											direction="row"
											spacing={1}
											alignItems="center"
											mb={{ md: 4 }}
										>
											<Field name={`morageOrRent.${index}.amount`}>
												{({ field, form, meta }) => (
													<>
														<Currency
															Lable={label.label}
															className={classes.MonthlyCreditInput}
															error={meta.touched && meta.error}
															onChange={(event, value) => {
																formik.setFieldTouched(field.name)
																formik.setFieldValue(field.name, value)
															}}
															value={meta.value}

														/>
													</>
												)}
											</Field>
											{index == 0 ? (
												<AddCircleIcon
													className={classes.PlusIcon}
													onClick={() => {
														if (arrayFieldAmount.morageOrRent < 4) {
															arrayHelpers.push(template);
															setArrayFieldAmount((prevData) => {
																return {
																	...prevData,
																	morageOrRent: prevData.morageOrRent + 1,
																};
															});
														}
													}}
												/>
											) : (
												<HighlightOffIcon
													className={classes.RemoveIcon}
													onClick={() => {
														removemorageOrRent(index)
														arrayHelpers.remove(index);
														setArrayFieldAmount((prevData) => {
															return {
																...prevData,
																morageOrRent: prevData.morageOrRent - 1,
															};
														});
													}}
												/>
											)}
										</Stack>

										<Stack mt={4}>
											<Field name={`morageOrRent.${index}.bankId`}>
												{({ field, form, meta }) => (
													<>
														<Select
															{...field}
															Lable="Bank"
															placeholder="Select financial institution"
															ApiUrl={`${baseUrl}/banks?filter={"order":"position"}`}
															error={meta.touched || meta.error}
															value={meta.value}
															onBlur={() => {
																formik.setFieldTouched(field.name);
															}}
															onChange={(e, value, reason) => {
																if (reason == "clear")
																	form.setFieldValue(field.name, "");
																else {
																	value &&
																		form.setFieldValue(field.name, value);
																}
															}}
														/>
													</>
												)}
											</Field>
										</Stack>
										<Stack direction="column" width={{ xs: "100%", sm: "49%" }} mt={2}>
											<Button
												Title="Upload Files"
												variant="h7"
												className={classes.UploadFiles}
												endIcon={<AttachFileIcon />}
												onClick={(e) => morgageOrRentFileRefClick(index, e)}
											/>
											<Field name={`morageOrRent.${index}.file`}>
												{({ field, form, meta }) => (
													<>
														<input
															{...field}
															type="file"
															accept="application/pdf,image/*"
															className={classes.MonthlyCreditInput}
															value=""
															style={{ display: "none" }}
															ref={(el) => (morgageOrRentFileRef.current[index] = el)}
															onChange={(event) => {
																formik.setFieldValue(
																	`morageOrRent.${index}.file`,
																	event.currentTarget.files[0]
																);
															}}
														/>
														<p className={classes.fileText}>
															Upload your loan statement from the financial institution selected.
															Statements retrieved from online banking are accepted.
														</p>
														{meta.error ? (
															<p className={classes.fileText} style={{ color: "red", marginTop: 0 }}>
																{meta.error}
															</p>
														) : (
															meta?.value?.name && (
																<p className={classes.uploadFileName}>
																	{`${String(meta.value.name).substring(0, 20)} ...`}
																	<span>
																		<ClearIcon
																			className={classes.deleteFileIcon}
																			onClick={() => {
																				formik.setFieldValue(field.name, null);
																			}}
																		/>
																	</span>
																</p>
															)
														)}
													</>
												)}
											</Field>
										</Stack>
									</Stack>
								))}
							</Fragment>
						)}
					/>
				);
			case 3:
				return (
					<FieldArray
						key={`creditCard${label.id}`}
						name="creditCard"
						render={(arrayHelpers) => (
							<div style={{padding: '10px 10px 60px 10px', backgroundColor: '#f5f4f4'}}>
								{formik.values.creditCard?.map((field, index) => (
									<Stack key={index} mt={4}>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center"
											mb={{ md: 4 }}
										>

											<Field name={`creditCard.${index}.amount`}>
												{({ field, form, meta }) => (
													<>
														<Currency
															Lable={label.label}
															className={classes.MonthlyCreditInput}
															error={meta.touched && meta.error}
															onChange={(event, value) => {
																formik.setFieldTouched(field.name)
																formik.setFieldValue(field.name, value)
															}}
															value={meta.value}
														/>
													</>
												)}
											</Field>
											{index == 0 ? (
												<AddCircleIcon
													className={classes.PlusIcon}
													onClick={() => {
														if (arrayFieldAmount.creditCard < 4) {
															arrayHelpers.push(template);
															setArrayFieldAmount((prevData) => {
																return {
																	...prevData,
																	creditCard: prevData.creditCard + 1,
																};
															});
														}
													}}
												/>
											) : (
												<HighlightOffIcon
													className={classes.RemoveIcon}
													onClick={() => {
														removecreditCard(index)
														arrayHelpers.remove(index);
														setArrayFieldAmount((prevData) => {
															return {
																...prevData,
																creditCard: prevData.creditCard - 1,
															};
														});
													}}
												/>
											)}

										</Stack>
										<Stack mt={4}>
											<Field name={`creditCard.${index}.bankId`}>
												{({ field, form, meta }) => (
													<>
														<Select
															{...field}
															Lable="Bank"
															placeholder="Select financial institution"
															ApiUrl={`${baseUrl}/banks?filter={"order":"position"}`}
															error={meta.touched || meta.error}
															value={meta.value}
															onBlur={() => {
																formik.setFieldTouched(field.name);
															}}
															onChange={(e, value, reason) => {
																if (reason == "clear")
																	form.setFieldValue(field.name, "");
																else {
																	value &&
																		form.setFieldValue(field.name, value);
																}
															}}
														/>
													</>
												)}
											</Field>
										</Stack>
										<Stack direction="column" width={{ xs: "100%", sm: "49%" }} mt={2}>
											<Button
												Title="Upload Files"
												variant="h7"
												className={classes.UploadFiles}
												endIcon={<AttachFileIcon />}
												onClick={(e) => creditCardRefClick(index, e)}
											/>
											<Field name={`creditCard.${index}.file`}>
												{({ field, form, meta }) => (
													<>
														<input
															{...field}
															type="file"
															accept="application/pdf,image/*"
															className={classes.MonthlyCreditInput}
															value=""
															style={{ display: "none" }}
															ref={(el) => (creditCardFileRef.current[index] = el)}
															onChange={(event) => {
																formik.setFieldValue(
																	`creditCard.${index}.file`,
																	event.currentTarget.files[0]
																);
															}}
														/>
														<p className={classes.fileText}>
															Upload your loan statement from the financial institution selected.
															Statements retrieved from online banking are accepted.
														</p>
														{meta.error ? (
															<p className={classes.fileText} style={{ color: "red", marginTop: 0 }}>
																{meta.error}
															</p>
														) : (
															meta?.value?.name && (
																<p className={classes.uploadFileName}>
																	{`${String(meta.value.name).substring(0, 20)} ...`}
																	<span>
																		<ClearIcon
																			className={classes.deleteFileIcon}
																			onClick={() => {
																				formik.setFieldValue(field.name, null);
																			}}
																		/>
																	</span>
																</p>
															)
														)}
													</>
												)}
											</Field>
										</Stack>
									</Stack>
								))}
							</div>
						)}
					/>
				);
			case 4:
				return (
					<FieldArray
						key={`overDraft${label.id}`}
						name="overDraft"
						render={(arrayHelpers) => (
							<Fragment>
								{formik.values.overDraft?.map((field, index) => (
									<Stack key={index} mt={4}>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center"
											mb={{ md: 4 }}
										>
											<Field name={`overDraft.${index}.amount`}>
												{({ field, form, meta }) => (
													<>
														<Currency
															Lable={label.label}
															className={classes.MonthlyCreditInput}
															error={meta.touched && meta.error}
															onChange={(event, value) => {
																formik.setFieldTouched(field.name)
																formik.setFieldValue(field.name, value)
															}}
															value={meta.value}
														/>
													</>
												)}
											</Field>
											{index == 0 ? (
												<AddCircleIcon
													className={classes.PlusIcon}
													onClick={() => {
														if (arrayFieldAmount.overDraft < 4) {
															arrayHelpers.push(template);
															setArrayFieldAmount((prevData) => {
																return {
																	...prevData,
																	overDraft: prevData.overDraft + 1,
																};
															});
														}
													}}
												/>
											) : (
												<HighlightOffIcon
													className={classes.RemoveIcon}
													onClick={() => {
														removeoverDraft(index)
														arrayHelpers.remove(index);
														setArrayFieldAmount((prevData) => {
															return {
																...prevData,
																overDraft: prevData.overDraft - 1,
															};
														});
													}}
												/>
											)}


										</Stack>
										<Stack mt={4}>
											<Field name={`overDraft.${index}.bankId`}>
												{({ field, form, meta }) => (
													<>
														<Select
															{...field}
															Lable="Bank"
															placeholder="Select financial institution"
															ApiUrl={`${baseUrl}/banks?filter={"order":"position"}`}
															error={meta.touched || meta.error}
															value={meta.value}
															onBlur={() => {
																formik.setFieldTouched(field.name);
															}}
															onChange={(e, value, reason) => {
																if (reason == "clear")
																	form.setFieldValue(field.name, "");
																else {
																	value &&
																		form.setFieldValue(field.name, value);
																}
															}}
														/>
													</>
												)}
											</Field>
										</Stack>
										<Stack direction="column" width={{ xs: "100%", sm: "49%" }} mt={2}>
											<Button
												Title="Upload Files"
												variant="h7"
												className={classes.UploadFiles}
												endIcon={<AttachFileIcon />}
												onClick={(e) => overDraftFileRefClick(index, e)}
											/>
											<Field name={`overDraft.${index}.file`}>
												{({ field, form, meta }) => (
													<>
														<input
															{...field}
															type="file"
															accept="application/pdf,image/*"
															className={classes.MonthlyCreditInput}
															value=""
															style={{ display: "none" }}
															ref={(el) => (overDraftFileRef.current[index] = el)}
															onChange={(event) => {
																formik.setFieldValue(
																	`overDraft.${index}.file`,
																	event.currentTarget.files[0]
																);
															}}
														/>
														<p className={classes.fileText}>
															Upload your loan statement from the financial institution selected.
															Statements retrieved from online banking are accepted.
														</p>
														{meta.error ? (
															<p className={classes.fileText} style={{ color: "red", marginTop: 0 }}>
																{meta.error}
															</p>
														) : (
															meta?.value?.name && (
																<p className={classes.uploadFileName}>
																	{`${String(meta.value.name).substring(0, 20)} ...`}
																	<span>
																		<ClearIcon
																			className={classes.deleteFileIcon}
																			onClick={() => {
																				formik.setFieldValue(field.name, null);
																			}}
																		/>
																	</span>
																</p>
															)
														)}
													</>
												)}
											</Field>
										</Stack>
									</Stack>
								))}
							</Fragment>
						)}
					/>
				);
			case 5:
				return (
					<FieldArray
						key={`other${label.id}`}
						name="other"
						render={(arrayHelpers) => (
							<div style={{padding: '10px 10px 60px 10px', backgroundColor: '#f5f4f4'}}>
								{formik.values.other?.map((field, index) => (
									<Stack key={index} mt={4}>
										<Stack
											direction="row"
											spacing={1}
											alignItems="center"
											mb={{ md: `${localStorage.getItem('loan') !== 'secure' || localStorage.getItem('loan') !== 'refinancing'} : '0' : '4'`}}
										>

											<Field name={`other.${index}.amount`}>
												{({ field, form, meta }) => (
													<>
														<Currency
															Lable={label.label}
															className={classes.MonthlyCreditInput}
															error={meta.touched && meta.error}
															onChange={(event, value) => {
																formik.setFieldTouched(field.name)
																formik.setFieldValue(field.name, value)
															}}
															value={meta.value}
														/>
													</>
												)}
											</Field>
											{index == 0 ? (
												<AddCircleIcon
													className={classes.PlusIcon}
													onClick={() => {
														if (arrayFieldAmount.other < 4) {
															arrayHelpers.push(template);
															setArrayFieldAmount((prevData) => {
																return {
																	...prevData,
																	other: prevData.other + 1,
																};
															});
														}
													}}
												/>
											) : (
												<HighlightOffIcon
													className={classes.RemoveIcon}
													onClick={() => {
														removeother(index)
														arrayHelpers.remove(index);
														setArrayFieldAmount((prevData) => {
															return {
																...prevData,
																other: prevData.other - 1,
															};
														});
													}}
												/>
											)}


										</Stack>
										{
											localStorage.getItem('loan') === 'secure' || localStorage.getItem('loan') === 'refinancing' && (
										<Stack mt={4}>
												<Field name={`other.${index}.bankId`}>
													{({ field, form, meta }) => (
														<>
															<Select
																{...field}
																Lable="Bank"
																placeholder="Select financial institution"
																ApiUrl={`${baseUrl}/banks?filter={"order":"position"}`}
																error={meta.touched || meta.error}
																value={meta.value}
																onBlur={() => {
																	formik.setFieldTouched(field.name);
																}}
																onChange={(e, value, reason) => {
																	if (reason == "clear")
																		form.setFieldValue(field.name, "");
																	else {
																		value &&
																			form.setFieldValue(field.name, value);
																	}
																}}
															/>
														</>
													)}
												</Field>
										</Stack>
											)
										}
										<Stack direction="column" width={{ xs: "100%", sm: "49%" }} mt={2}>
											<Button
												Title="Upload Files"
												variant="h7"
												className={classes.UploadFiles}
												endIcon={<AttachFileIcon />}
												onClick={(e) => otherCreditObligationFileRefClick(index, e)}
											/>
											<Field name={`other.${index}.file`}>
												{({ field, form, meta }) => (
													<>
														<input
															{...field}
															type="file"
															accept="application/pdf,image/*"
															className={classes.MonthlyCreditInput}
															value=""
															style={{ display: "none" }}
															ref={(el) => (otherCreditObligationFileRef.current[index] = el)}
															onChange={(event) => {
																formik.setFieldValue(`other.${index}.file`, event.currentTarget.files[0]);
															}}
														/>
														<p className={classes.fileText}>
															Upload your loan statement from the financial institution selected. Statements
															retrieved from online banking are accepted.
														</p>
														{meta.error ? (
															<p className={classes.fileText} style={{ color: "red", marginTop: 0 }}>
																{meta.error}
															</p>
														) : (
															meta?.value?.name && (
																<p className={classes.uploadFileName}>
																	{`${String(meta.value.name).substring(0, 20)} ...`}
																	<span>
																		<ClearIcon
																			className={classes.deleteFileIcon}
																			onClick={() => {
																				formik.setFieldValue(field.name, null);
																			}}
																		/>
																	</span>
																</p>
															)
														)}
													</>
												)}
											</Field>
										</Stack>
									</Stack>
								))}
							</div>
						)}
					/>
				);
				case 6:
					return (
						<FieldArray name="rent" key={`rent${label.id}`}>
							{(arrayHelpers) => (
								<Fragment>
									{formik.values.rent?.map((field, index) => (
										<Stack key={index} mt={4}>
											<Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 4, sm: 2 }} width="100%">
												<Stack direction="row" spacing={1} alignItems="center" width={{ xs: '100%', sm: '50%' }}>
													<Field name={`rent.${index}.amount`}>
														{({ field, form, meta }) => (
	
															<>
																<Currency
																	Lable={label.label}
																	className={classes.MonthlyCreditInput}
																	error={meta.touched && meta.error}
																	onChange={(event, value) => {
																		formik.setFieldTouched(field.name)
																		formik.setFieldValue(field.name, value)
																	}}
																	value={meta.value}
																/>
															</>
														)}
													</Field>
												</Stack>
											</Stack>
										</Stack>
									))}
								</Fragment>
							)}
						</FieldArray>
					)
					default:
					return null
		}

	};

	console.log(formik.values)

	return (
		<Stack
			direction={{ xs: "row", sm: "column" }}
			spacing={7}
			className={classes.MonthlyCreditDataContainer}
		>
			<Form>
				<Stack mt={2}>
					<FieldArray name='ExitingLoansAndObligations' render={(arrayHelpers) => (
						<Fragment>
							{formik.values.ExitingLoansAndObligations?.map((field, index) => (
								<Stack key={index}>
									<Stack direction="row"
											spacing={1}
											alignItems="center"
											mb={{ md: 1 }} >
									<Field name={`ExitingLoansAndObligations.${index}`}>
										{({ field, form, meta }) => (
											<>
												<Currency
													Lable='LOANS AND OTHER CREDIT OBLIGATIONS WITH US'
													className={classes.MonthlyCreditInput}
													error={meta.touched && meta.error}
													onChange={(event, value) => {
														formik.setFieldTouched(field.name)
														formik.setFieldValue(field.name, value)
													}}
													value={meta.value}
													readOnly
												/>

											</>
										)}
									</Field>
										</Stack>
								</Stack>
							))}
						</Fragment>
					)}
					/>

				</Stack>
				{LabelList.map((label) => {
					return <Box key={label.id}>{renderFieldArray(label)}</Box>;
				})}
			</Form>
		</Stack>
	);
};

export default MonthlyCreditData;