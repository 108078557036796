import React, { useEffect, useState } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
  styled
} from '@mui/material'
import Image from '../../../../../../Components/AtomicDesign/atoms/Image/Image'
import { LoanTermsDetails, LoanTermsDetailsFully } from './LoanTermDetails'
import { useStyles } from './LoanDetailsStyle'
import { Stack, Box } from '@mui/system'
import Input from '../../../../../../Components/Inputs/Input'
import MuiInput from '@mui/joy/Input'
import clsx from 'clsx'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { useSelector } from 'react-redux'
import { FormikProvider, useFormik, Field } from 'formik'
import Currency from '../../../../../../Components/Inputs/currency'
import ModalContainer from '../../../../../../Components/AtomicDesign/atoms/ModalContainer/ModalContainer'
import { Button } from '../../../../../components'
import { hasDeleteLoanRequest } from '../../../../../../Store/context/deleteLoanRequest'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import './styles.css'
import ExclamationIcon from '../../../../../../assets/Images/exclamation.png'
import IconBlue from '../../../../../../assets/Images/icon-blue.png'
import ApprovedCheckmark from '../../../../../../assets/Images/approvedcheckmark.png'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

// const formatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'BZD'
// })

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: 'white',
//     color: 'rgba(0, 0, 0, 0.87)',
//     fontSize: 11,
//     maxWidth: 550,
//     filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))'
//   },
//   [`& .${tooltipClasses.arrow}`]: {
//     color: 'white',
//     '&::before': {
//       backgroundColor: 'white'
//     }
//   }
// }))

const LightTooltip2 = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: '#0F5CAC',
    fontSize: 14,
    maxWidth: 264,
    filter: 'drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))',
    padding: '1rem',
    "@media (max-width: 405px)": {
      maxWidth: 200,
    },
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    '&::before': {
      backgroundColor: 'white'
    }
  }
}))

const LoanDetails = ({
  setAmount,
  amount,
  setTerms,
  terms,
  error,
  errorTerms,
  typeLoan,
  typeOfCollateral,
  setLandValue,
  landValue,
  typeOfLandTransfer,
  setTypeOfLandTransfer,
  totalColCoverValue,
  messageErrorInButtonContinue = () => {},
  disabledContinue = () => {}
}) => {
  const { currentLoanBalance } = useSelector((state) => state.getLoanRequest)
  const {
    amount: monto,
    terms: terminos,
    landValue: montoCompra,
    legalFeesTypeId,
    totalLoanRequests,
    percentagePreQualifiedAccepted,
    totalPreQualifiedLoanValue
  } = useSelector((state) => state.LoanRequestReducer)


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const classes = useStyles()

  const [showTooltip, setShowTooltip] = useState(false) // Estado para mostrar u ocultar el Tooltip
  const [showTooltip1, setShowTooltip1] = useState(false)
  const [showTooltip2, setShowTooltip2] = useState(false)
  const [loanRequest, setLoanRequest] = useState(totalLoanRequests)

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const toggleTooltip1 = () => {
    setShowTooltip1(!showTooltip1)
  }

  const toggleTooltip2 = () => {
    setShowTooltip2(!showTooltip2)
  }

  const [errorMessage, setErrorMessage] = useState(false)
  const [activeId, setActiveId] = useState()
  const [activeLoanTerms, setActiveLoanTerms] = useState()
  const formik = useFormik({
    initialValues: {
      other1: '',
      other2: ''
    }
  })

  // const [localAmount, setLocalAmount] = useState(amount || 0)
  const [loalLandValueAmounr, setLoalLandValueAmounr] = useState(landValue || 0)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenRefinance, setIsModalOpenRefinance] = useState(false)
  const [continueSecured, setContinueSecured] = useState(false)
  const [errorMessageNewFunds, seterrorMessageNewFunds] = useState('')
  const [open, setOpen] = React.useState(false)
  const [loanValueOnChange, setLoanValueOnChange] = useState()
  const [loading, setLoading] = useState(false)

  const warrantyTypeID = sessionStorage.getItem('warrantyTypeID')
  const loanTypeID = sessionStorage.getItem('loan')

  useEffect(() => {
    if (
      amount === loanValueOnChange &&
      loanTypeID === 'refinancing' &&
      terms >= 12
    ) {
      setLoading(true)
    }
  }, [amount, loanValueOnChange, terms])

  useEffect(() => {
    if (
      loading &&
      totalLoanRequests < totalPreQualifiedLoanValue &&
      amount === loanValueOnChange &&
      terms >= 12 &&
      loanRequest === totalLoanRequests
    ) {
      setTimeout(() => {
        setLoading(false)
        setIsModalOpenRefinance(true)
        return
      }, 3000)
    }

    if (
      loading &&
      totalLoanRequests >= totalPreQualifiedLoanValue &&
      amount === loanValueOnChange &&
      terms >= 12 &&
      loanRequest === totalLoanRequests
    ) {
      setTimeout(() => {
        setLoading(false)
        setIsModalOpenRefinance(false)
      }, 3000)
    }
  }, [
    loading,
    totalLoanRequests,
    totalPreQualifiedLoanValue,
    amount,
    loanValueOnChange,
    loanRequest
  ])

  useEffect(() => {
    if (amount >= 1000) {
      setLoanRequest(amount + currentLoanBalance)
    }

    if (
      Number(amount) > Number(totalPreQualifiedLoanValue) &&
      sessionStorage.getItem('loan') === 'refinancing'
    ) {
      seterrorMessageNewFunds(true)
    } else {
      seterrorMessageNewFunds(false)
    }
  }, [amount])

  const isVehicle = warrantyTypeID === '2'

  // useEffect(() => {
  //   if (monto > 0) {
  //     const amt = LoanAmountDetails.find((o) => o.value === monto)
  //     if (amt) {
  //       setActiveId(amt.id)
  //       setLocalAmount(amt.value) // Actualizar el estado local al valor encontrado
  //     } else {
  //       setActiveId(8)
  //       setLocalAmount(monto) // Actualizar el estado local con "monto" en caso de "OTHER"
  //       formik.setFieldValue('other1', monto)
  //     }
  //   }
  // }, [monto])

  useEffect(() => {
    if (terminos > 0) {
      const tems = LoanTermsDetailsFully.find((o) => o.value === terminos)
      if (tems) setActiveLoanTerms(tems.id)
      else if (terms > 60) {
        setActiveLoanTerms(8)
        formik.setFieldValue('other2', '')
      } else {
        setActiveLoanTerms(8)
        formik.setFieldValue('other2', terminos)
      }
    }
  }, [terminos])

  const handleChange = (event) => {
    const newValue = parseFloat(event.target.value.replace(/,/g, '')) // Intentar convertir el valor en un número
    // console.log(newValue)
    // const removeComma = newValue.replace(/,/g, '')
    if (!isNaN(newValue)) {
      setLoanValueOnChange(newValue)
      // setAmount(newValue)
      // Solo establecer el valor en el campo si es un número válido
      // setAmount(newValue)
      // setLocalAmount(newValue)
    }
    if (isNaN(newValue)) {
      setAmount('')
      // setLocalAmount('')
      return
    }

    setLoanValueOnChange(newValue)
  }

  const [landValueChange, setLandValueChange] = useState()

  const handleLandValueChange = (e) => {
    const newValue = parseFloat(e.target.value.replace(/,/g, '')) // Intentar convertir el valor en un número
    if (!isNaN(newValue)) {
      // Solo establecer el valor en el campo si es un número válido
      setLandValueChange(newValue)
      // setLoalLandValueAmounr(landValueChange)
    }
    if (isNaN(newValue)) {
      setLandValue('')
      return
    }
    setLandValueChange(newValue)
  }

  const validator = (landValue, purchasePrice) => {
    if (landValue < purchasePrice) {
      setErrorMessage(true)
    } else {
      setErrorMessage(false)
    }
  }

  const [customErrorMessageLand, setCustomErrorMessageLand] = useState(false)

  useEffect(() => {
    if (amount && (amount < 7500 || amount > 150_000) && loanTypeID === 'secure' && warrantyTypeID === '3') {
      disabledContinue(true)
      setCustomErrorMessageLand("The purchase price must be greater than $7,500 but not exceeding $150,000 and the term must be equal to or lower than 120 months.")
      messageErrorInButtonContinue("Please correct the errors before continuing.")
      return
    } else {
      disabledContinue(false)
      setCustomErrorMessageLand("")
      messageErrorInButtonContinue('')
    }

    if ((landValueChange < amount) && loanTypeID === 'secure' && warrantyTypeID === '3') {
      disabledContinue(true)
      setErrorMessage(true)
      messageErrorInButtonContinue("Please correct the errors before continuing.")
    } else {
      disabledContinue(false)
      setErrorMessage(false)
      messageErrorInButtonContinue('')
    }
  }, [landValueChange, amount])
  // console.log(customErrorMessageLand)

  useEffect(() => {
    if (landValue) setLandValueChange(landValue)
  }, [landValue])

  useEffect(() => {
    console.log({ amount, terms })
    if (loanTypeID === 'secure' && warrantyTypeID === '3') {
      if (amount > 150_000 || terms > 120) {
        disabledContinue(true)
      } else disabledContinue(false)
    }
  }, [amount, terms])

  // console.log({"LAND VALUE": landValue, "AMOUNT": amount, "monto": monto, "Monto compra": montoCompra, "LANDcHANGE": landValueChange})

  // useEffect(() => {
  //   if (
  //     loanTypeID === 'secure' &&
  //     warrantyTypeID === '3' &&
  //     amount >= 1000 &&
  //     landValue >= 1000
  //   ) {
  //     validator(landValue, amount)
  //   }
  // }, [landValue, loanTypeID, warrantyTypeID])

  const tableLoan = [
    {
      id: 1,
      loanDetail: 'Fees',
      unsecuredVehicleMonth: `${
        warrantyTypeID === '2' ? '$300' : 'Minimum $300'
      }`,
      securedVehicleLoan: `${
        warrantyTypeID === '2'
          ? '$300 + $435 Bill of Sale'
          : 'Minimum $300 + Legal Fees'
      }`
    },
    {
      id: 2,
      loanDetail: 'Interest Rate',
      unsecuredVehicleMonth: '11% or greater',
      securedVehicleLoan: 'Less than 11%'
    },
    {
      id: 3,
      loanDetail: 'Time-to-disbursement',
      unsecuredVehicleMonth: '1 to 3 business days',
      securedVehicleLoan: `${
        warrantyTypeID === '2'
          ? 'Up to 10 business days'
          : 'Can take up to 60 days'
      }`
    }
  ]

  const handleOnBlur = (e) => {
    const value = parseFloat(e.target.value.replace(/,/g, ''))
    if (value >= 1000 && value < 7500) {
      setIsModalOpen(true)
    }

    setAmount(loanValueOnChange !== undefined ? loanValueOnChange : amount)
    // setLocalAmount(loanValueOnChange)
    console.log("Verificando enviar los datos, se ecuentra en el rango de 7500 a 150000", loanValueOnChange, amount)
  }

  const handleBlurLand = (e) => {
    const value = parseFloat(e.target.value.replace(/,/g, ''))
    if (value > 1000) {
      setLandValue(landValueChange)
      setLoalLandValueAmounr(landValueChange)
    }
  }

  return (
    <>
      {loading && (
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      )}
      <FormikProvider value={formik}>
        <Stack direction='column' className={classes.Input}>
          {warrantyTypeID === '3' && loanTypeID === 'secure' && (
            <>
              <FormControl sx={{ marginBottom: '1.5rem' }}>
                <FormLabel
                  sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                >
                  WHAT TYPE OF LAND TRANSFER PROCESS WILL YOU REQUIRE?
                </FormLabel>
                <RadioGroup
                  defaultValue={''}
                  value={typeOfLandTransfer}
                  onChange={(e) => setTypeOfLandTransfer(e.target.value)}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <FormControlLabel
                      style={{ marginRight: '5px' }}
                      value={1}
                      control={<Radio />}
                      label={
                        <Typography className={classes.checkboxLabel}>
                          Direct Person-To-Person Transfer of Land
                        </Typography>
                      }
                    />
                    <LightTooltip2
                      onClick={toggleTooltip1}
                      open={showTooltip1}
                      placement='bottom'
                      title={
                        'Select this option if the land title is clear of any lien or charges.'
                      }
                    >
                      <img
                        onMouseEnter={() => setShowTooltip1(true)}
                        onMouseLeave={() => setShowTooltip1(false)}
                        onClick={(e) => {
                          e.stopPropagation()
                          toggleTooltip1()
                        }}
                        src={IconBlue}
                        height={'15px'}
                        alt=''
                      />
                    </LightTooltip2>
                  </div>
                  <div
                    className={classes.widthRadioBtn}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                      width: '94%'
                    }}
                  >
                    <FormControlLabel
                      style={{ marginRight: '5px' }}
                      value={2}
                      control={<Radio />}
                      label={
                        <Typography className={classes.checkboxLabel}>
                          Transfer of Land that is Currently Mortgaged{' '}
                          <span style={{ position: 'relative' }}>
                            <LightTooltip2
                              onClick={toggleTooltip2}
                              placement='bottom'
                              open={showTooltip2}
                              title={
                                'Select this option when the seller confirms that the land being sold to you is currently assigned to a financial institution.'
                              }
                            >
                              <img
                                style={{
                                  paddingTop: '0.2rem',
                                  position: 'absolute',
                                  marginLeft: '0.3rem'
                                }}
                                onMouseEnter={() => setShowTooltip2(true)}
                                onMouseLeave={() => setShowTooltip2(false)}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  toggleTooltip2()
                                }}
                                src={IconBlue}
                                width={'15px'}
                                height={'15px'}
                                alt=''
                              />
                            </LightTooltip2>
                          </span>
                        </Typography>
                      }
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </>
          )}

          {warrantyTypeID === '3' && loanTypeID === 'secure' && (
            <>
              <Typography className={classes.InputTitle}>
                ESTIMATED LAND VALUE
              </Typography>
              <Currency
                className={classes.currency}
                disabled={
                  loanTypeID === 'secure' &&
                  warrantyTypeID === '3' &&
                  typeOfLandTransfer === 0
                }
                value={landValue}
                onChange={handleLandValueChange}
                onBlur={(e) => {
                  handleBlurLand(e)
                }}
                placeholder='Enter land value'
              />
              {/* <MuiInput
                disabled={warrantyTypeID === '3' && typeOfLandTransfer === 0}
                min={0}
                sx={{ mt: 2 }}
                startDecorator={'BZ $'}
                type='text'
                value={landValue.toLocaleString()}
                placeholder='Enter land value'
                onChange={handleLandValueChange}
                format={{
                  pattern: '###,###.00', // Patrón de formato
                  decimalSeparator: '.', // Separador decimal
                  thousandSeparator: ',' // Separador de miles
                }}
                style={{ margin: '16px 0' }}
              /> */}
            </>
          )}

          <Typography paddingTop={'1rem'} className={classes.InputTitle}>
            {(loanTypeID === 'secure') & isVehicle
              ? 'LOAN AMOUNT'
              : loanTypeID === 'secure' && warrantyTypeID === '3'
              ? 'PURCHASE PRICE'
              : 'NEW FUNDS REQUIRED'}
          </Typography>
          <Currency
            className={classes.currency}
            disabled={
              loanTypeID === 'secure' &&
              warrantyTypeID === '3' &&
              typeOfLandTransfer === 0
            }
            value={amount ? String(amount) : ''}
            onChange={handleChange}
            onBlur={(e) => {
              handleOnBlur(e)
            }}
            placeholder={
              loanTypeID === 'land' && warrantyTypeID === '3'
                ? 'Enter land amount'
                : loanTypeID === 'refinancing'
                ? 'Enter loan amount'
                : ''
            }
          />
          {/* <MuiInput
            disabled={
              loanTypeID === 'secure' &&
              warrantyTypeID === '3' &&
              typeOfLandTransfer === 0
            }
            min={0}
            sx={{ marginTop: '16px' }}
            startDecorator={'BZ $'}
            type='text'
            value={
              loanValueOnChange !== undefined
                ? Number(loanValueOnChange).toLocaleString('en', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })
                : Number(localAmount).toLocaleString()
            }
            placeholder={
              warrantyTypeID === '3'
                ? 'Enter land amount'
                : loanTypeID === 'refinancing'
                ? 'Enter requested loan amount'
                : ''
            }
            onChange={handleChange}
            onBlur={(e) => {
              handleOnBlur(e)
            }}
            format={{
              pattern: '###.###,00', // Patrón de formato
              decimalSeparator: ',', // Separador decimal
              thousandSeparator: '.' // Separador de miles
            }}
            // style={{ margin: '16px 0' }}
          /> */}
          {amount < 1000 &&
            loanTypeID === 'secure' &&
            warrantyTypeID !== '3' && (
              <Typography
                className={classes.InputTitle}
                style={{ color: 'red', marginBottom: '1rem' }}
              >
                Must be greater than 1000.
              </Typography>
            )}

          {loanTypeID === 'refinancing' && (
            <>
              <Typography mt={2} className={classes.InputTitle}>
                CURRENT LOAN BALANCE
              </Typography>
              <MuiInput
                className={classes.BlankInput}
                disabled
                min={0}
                sx={{ mt: 2 }}
                startDecorator={'BZ $'}
                type='text'
                value={
                  currentLoanBalance !== null
                    ? currentLoanBalance?.toLocaleString()
                    : ''
                }
                // placeholder='Enter land value'
                // onChange={(e) => setLandValue(e.target.value)}
                format={{
                  pattern: '###.###,00', // Patrón de formato
                  decimalSeparator: ',', // Separador decimal
                  thousandSeparator: '.' // Separador de miles
                }}
                style={{ margin: '16px 0' }}
              />
            </>
          )}

          {loanTypeID === 'refinancing' && (
            <>
              <Typography className={classes.InputTitle}>
                TOTAL LOAN REQUEST
              </Typography>
              <MuiInput
                className={classes.BlankInput}
                disabled
                min={0}
                sx={{ mt: 2 }}
                startDecorator={'BZ $'}
                type='text'
                value={
                  totalLoanRequests !== null && totalLoanRequests !== undefined
                    ? totalLoanRequests?.toLocaleString()
                    : ''
                }
                // placeholder='Enter land value'
                // onChange={(e) => setLandValue(e.target.value)}
                format={{
                  pattern: '###.###,00', // Patrón de formato
                  decimalSeparator: ',', // Separador decimal
                  thousandSeparator: '.' // Separador de miles
                }}
                style={{ margin: '16px 0' }}
              />
            </>
          )}

          {loanTypeID === 'refinancing' && (
            <>
              <Typography className={classes.InputTitle}>
                PRE-QUALIFIED LOAN VALUE
              </Typography>
              <MuiInput
                className={classes.BlankInput}
                disabled
                min={0}
                sx={{ mt: 2 }}
                startDecorator={'BZ $'}
                type='text'
                value={
                  totalPreQualifiedLoanValue !== null
                    ? totalPreQualifiedLoanValue?.toLocaleString()
                    : ''
                }
                // placeholder='Enter land value'
                // onChange={(e) => setLandValue(e.target.value)}
                format={{
                  pattern: '###.###,00', // Patrón de formato
                  decimalSeparator: ',', // Separador decimal
                  thousandSeparator: '.' // Separador de miles
                }}
                style={{ margin: '16px 0' }}
              />
            </>
          )}

          {loanTypeID === 'refinancing' && (
            <>
              <Typography className={classes.InputTitle}>
                % OF PRE-QUALIFIED LOAN AMOUNT ACCEPTED
              </Typography>
              <MuiInput
                className={classes.BlankInput}
                disabled
                min={0}
                sx={{ mt: 2 }}
                // startDecorator={'BZ $'}
                // endDecorator={'%'}
                type='text'
                value={`${
                  percentagePreQualifiedAccepted !== null
                    ? percentagePreQualifiedAccepted?.toFixed()
                    : ''
                }%`}
                // placeholder='Enter land value'
                // onChange={(e) => setLandValue(e.target.value)}
                format={{
                  pattern: '###.###,00', // Patrón de formato
                  decimalSeparator: ',', // Separador decimal
                  thousandSeparator: '.' // Separador de miles
                }}
                style={{ margin: '16px 0' }}
              />
            </>
          )}
          {errorMessage &&
            loanTypeID === 'secure' &&
            warrantyTypeID === '3' && (
              <Typography
                className={classes.InputTitle}
                style={{ color: 'red', marginBottom: '1rem' }}
              >
                Purchase price cannot exceed land value.
              </Typography>
            )}

            {customErrorMessageLand && (
              <Typography
                className={classes.InputTitle}
                style={{ color: 'red', marginBottom: '1rem' }}
              >
                {customErrorMessageLand}
              </Typography>
            )}
          {/* {localAmount > 999 &&
            localAmount < 7500 &&
            loanTypeID === 'secure' &&
            isVehicle && (
              <Title
                Text={
                  'Vehicle loan applications must be above $7,500 to proceed as a secured loan. '
                }
                color={'red'}
                aling={'left'}
                fontWeight={'500'}
              />
            )} */}

          {/* {typeLoan !== 'SECURE' &&
            LoanAmountDetails.map((amount) => (
              <LightTooltip
                title={localAmount === 7500 ? <LoanAmountTooltip /> : ''}
                arrow
                placement='bottom'
                key={amount.id}
                open={open}
                onClick={() => setOpen(!open)}
              >
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  className={[
                    classes.InputBox,
                    activeId === amount.id ? 'active' : ''
                  ]}
                  onClick={() => {
                    setAmount(amount.value)
                    setActiveId(amount.id)
                  }}
                >
                  <Typography>{amount.amount}</Typography>
                </Stack>
              </LightTooltip>
            ))} */}
          {typeLoan !== 'SECURE' && (
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              className={[
                clsx(classes.InputBox, classes.OtherButton),
                activeId === 8 ? 'active' : ''
              ]}
              onClick={() => {
                setActiveId(8)
              }}
            >
              <Typography>OTHER</Typography>
            </Stack>
          )}

          {/* {activeId === 8 && typeLoan !== 'SECURE' && (
            <Box>
              <Field name='other1'>
                {({ field, meta }) => (
                  <>
                    {meta.value !== 0 &&
                      meta.value !== '' &&
                      setAmount(meta.value)}
                    <Currency
                      error={formik.touched.other1 ? error : ''}
                      value={meta.value}
                      onChange={(event, value) => {
                        formik.setFieldValue(field.name, value)
                        formik.setFieldTouched(field.name)
                        setAmount(parseFloat(value) ? parseFloat(value) : '')
                      }}
                    />
                  </>
                )}
              </Field>
            </Box>
          )} */}
        </Stack>

        <Stack direction='column' className={classes.Input}>
          <Typography className={classes.InputTitle}>
            LOAN TERM{' '}
            {loanTypeID === 'secure' && warrantyTypeID === '3'
              ? '(Select one value or select OTHER and enter the desired term up to a maximum of 120 months)'
              : loanTypeID === 'secure' && warrantyTypeID === '2'
              ? '(The term for vehicle loans must not exceed 60 months. Please select your preferred option.)'
              : '(select one value or select OTHER and enter the desired term up a maxium of 60 months)'}
          </Typography>
          <MuiInput
            className={classes.BlankInput}
            type='text'
            value={`${terms || terminos.valueTerm || ''} months`}
            placeholder={`${terms || terminos.terms || ''}`}
            // disabled
            readOnly
            disabled={true}
            style={{ margin: '16px 0' }}
          />
          {typeOfCollateral !== 'fully'
            ? LoanTermsDetails.map(
                (
                  term // LoanTermsDetails
                ) => (
                  <Stack
                    key={term.id}
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    className={[
                      classes.InputBox,
                      activeLoanTerms === term.id ? 'active' : ''
                    ]}
                    onClick={() => {
                      setTerms(term.value)
                      setActiveLoanTerms(term.id)
                    }}
                  >
                    <Typography>{term.terms}</Typography>
                  </Stack>
                )
              )
            : LoanTermsDetails.map((term) => (
                <Stack
                  key={term.id}
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  className={[
                    classes.InputBox,
                    activeLoanTerms === term.id ? 'active' : ''
                  ]}
                  onClick={() => {
                    setTerms(term.value)
                    setActiveLoanTerms(term.id)
                  }}
                >
                  <Typography>{term.terms}</Typography>
                </Stack>
              ))}
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            className={[
              clsx(classes.InputBox, classes.OtherButton),
              activeLoanTerms === 8 ? 'active' : ''
            ]}
            onClick={() => {
              setTerms('')
              setActiveLoanTerms(8)
            }}
          >
            <Typography>OTHER</Typography>
          </Stack>
          {activeLoanTerms === 8 && (
            <Box>
              <Field name='other2'>
                {({ field, meta }) => (
                  <>
                    {meta.value !== 0 &&
                      meta.value !== '' &&
                      setTerms(meta.value)}
                    <Input
                      // className={classes.BlankInput}
                      type='number'
                      error={errorTerms}
                      inputProps={{ min: 12, step: 1 }}
                      value={meta.value}
                      onChange={(e) => {
                        let v = e.target.value ? parseInt(e.target.value) : ''
                        formik.setFieldValue(field.name, v)
                        setTerms(v)
                      }}
                      style={{ paddingBottom: 17 }}
                    />
                  </>
                )}
              </Field>
            </Box>
          )}
        </Stack>
      </FormikProvider>

      {((loanTypeID === 'secure' && warrantyTypeID === '2') ||
        (loanTypeID === 'secure' && warrantyTypeID === '3')) && (
        <ModalContainer
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          width={'900px'}
        >
          <div className={classes.ModalVehicle}>
            <img src={ExclamationIcon} alt='' />
            <Typography
              className={classes.headerSize}
              // width={'70%'}
              fontSize={'15.5px'}
              fontWeight={700}
              color={'#606060'}
              sx={{
                margin: '0 auto !important',
                marginBottom: '1rem !important'
              }}
            >
              {loanTypeID === 'secure' && warrantyTypeID === '2'
                ? 'Vehicle loans below $7,500 can be submitted as unsecured loans. You may choose to proceed with a secured facility of $7,500 or greater.'
                : 'Land loans below $7,500 can be submitted as unsecured loans. You may choose to proceed with a secured facility of $7,500 or greater.'}
            </Typography>
            <Typography
              className={classes.TypographySize}
              fontSize={'14px'}
              color={'#A3A3A3'}
            >
              {loanTypeID === 'secure' && warrantyTypeID === '2'
                ? 'If you wish to proceed with an unsecured loan, you can restart your application and select the unsecured personal loan option.'
                : 'If you wish to proceed with an unsecured loan, you can restart your application and select the unsecured personal loans option or continue with a secured loan of a higher value.'}
            </Typography>

            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                borderRadius: '0.3125rem',
                border: '1px solid rgba(0, 0, 0, 0.20)',
                background: '#FFF',
                boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
                marginTop: '2rem'
              }}
            >
              <Typography
                className={classes.TypographySize}
                sx={{
                  bgcolor: '#275DA6',
                  color: '#fff',
                  padding: '0.5rem 0',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                fontWeight={'700'}
              >
                LOAN DETAILS
              </Typography>
              <Typography
                className={classes.TypographySize}
                sx={{ bgcolor: '#275DA6', color: '#fff', padding: '0.5rem 0' }}
                fontWeight={'700'}
              >
                UNSECURED{' '}
                {loanTypeID === 'secure' && warrantyTypeID === '2'
                  ? 'VEHICLE'
                  : 'LAND'}{' '}
                LOAN
              </Typography>
              <Typography
                className={classes.TypographySize}
                sx={{ bgcolor: '#275DA6', color: '#fff', padding: '0.5rem 0' }}
                fontWeight={'700'}
              >
                SECURED{' '}
                {loanTypeID === 'secure' && warrantyTypeID === '2'
                  ? 'VEHICLE'
                  : 'LAND'}{' '}
                LOAN
              </Typography>

              {tableLoan.map((item) => {
                return (
                  <>
                    <Typography
                      className={classes.TypographySize}
                      sx={{
                        padding: '0.5rem',
                        bgcolor: `${item.id % 2 ? '#fff' : '#275DA642'}`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      color={'#275DA6'}
                    >
                      {item.loanDetail}
                    </Typography>
                    <Typography
                      className={classes.TypographySize}
                      sx={{
                        padding: '0.5rem',
                        bgcolor: `${item.id % 2 ? '#fff' : '#275DA642'}`,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                      color={'#275DA6'}
                    >
                      {item.unsecuredVehicleMonth}
                    </Typography>
                    <Typography
                      className={classes.TypographySize}
                      sx={{
                        padding: '0.5rem',
                        bgcolor: `${item.id % 2 ? '#fff' : '#275DA642'}`
                      }}
                      color={'#275DA6'}
                    >
                      {item.securedVehicleLoan}
                      {item.id === 1 && warrantyTypeID === '2' ? (
                        <div
                          onClick={toggleTooltip}
                          style={{
                            cursor: 'pointer',
                            display: 'inline-block',
                            marginLeft: '2%'
                          }}
                        >
                          <Tooltip
                            open={showTooltip}
                            title={
                              'This is the Bill of Sale amount for a $7,500 loan.  '
                            }
                            placement='left'
                            classes={{ tooltip: classes.tooltip }} // Clase personalizada para el tooltip
                            PopperProps={{
                              disablePortal: false // Evitar problemas de posicionamiento
                            }}
                          >
                            <div
                              style={{
                                width: '5px',
                                // bottom: '7px',
                                // right: '18px',
                                // background: 'blue',
                                display: 'inline-block'
                              }}
                              onMouseEnter={() => setShowTooltip(true)}
                              onMouseLeave={() => setShowTooltip(false)}
                              onClick={(e) => {
                                e.stopPropagation()
                                toggleTooltip()
                              }}
                            >
                              <Image
                                className='info-icon'
                                src={IconBlue}
                                style={{ cursor: 'pointer' }}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      ) : (
                        ''
                      )}
                    </Typography>
                  </>
                )
              })}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '2rem',
                marginBottom: '2rem',
                gap: '1rem',
                flexWrap: 'wrap'
              }}
            >
              <Button
                className={classes.PrimaryButton}
                Title='Continue as secured loan'
                variant='subtitle1'
                BtnVariant='contained'
                color='primary'
                onClick={() => {
                  setIsModalOpen(false)
                  // setLocalAmount('')
                  setAmount('')
                  setContinueSecured(true)
                }}
              />
              <Button
                className={classes.SecondaryButton}
                Title='Cancel and restart application'
                variant='subtitle1'
                BtnVariant='contained'
                color='secondary'
                onClick={() => {
                  dispatch(hasDeleteLoanRequest())
                  setIsModalOpen(false)
                  navigate(`/?q=${sessionStorage.getItem('code')}`)
                }}
              />
            </div>
          </div>
        </ModalContainer>
      )}

      {loanTypeID === 'refinancing' && (
        <ModalContainer
          open={isModalOpenRefinance}
          onClose={() => setIsModalOpenRefinance(false)}
          width={'600px'}
        >
          <div style={{ padding: '1rem', textAlign: 'center' }}>
            <img src={ApprovedCheckmark} alt='' />
            <Typography>
              You have been pre-qualified for a higher loan amount. If you’d
              like to adjust your loan request, please update the ‘new funds
              required’ field accordingly.
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '2rem',
                marginBottom: '2rem',
                gap: '1rem',
                flexWrap: 'wrap'
              }}
            >
              {/* <Button
                className={classes.SecondaryButton}
                Title='CHANGE REQUEST'
                variant='subtitle1'
                BtnVariant='contained'
                color='secondary'
                onClick={() => {
                  setIsModalOpen(false)
                  setContinueSecured(true)
                }}
              /> */}
              <Button
                className={classes.PrimaryButton}
                Title='PROCEED'
                variant='subtitle1'
                BtnVariant='contained'
                color='secondary'
                onClick={() => {
                  setIsModalOpenRefinance(false)
                  setContinueSecured(true)
                  setLoading(false)
                }}
              />
            </div>
          </div>
        </ModalContainer>
      )}

      {/* {loanTypeID === 'refinancing' && (
        <ModalContainer
          open={isModalOpen2}
          onClose={() => setIsModalOpen(false)}
          width={'600px'}
        >
          <div style={{ padding: '1rem', textAlign: 'center' }}>
            <img src={ApprovedCheckmark} alt='' />
            <Typography>
              You are pre-qualified for a higher loan value. Do you wish to
              proceed or change the new funds required.
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginTop: '2rem',
                marginBottom: '2rem',
                gap: '1rem',
                flexWrap: 'wrap'
              }}
            >
              <Button
                className={classes.SecondaryButton}
                Title='CHANGE REQUEST'
                variant='subtitle1'
                BtnVariant='contained'
                color='secondary'
                onClick={() => {
                  setIsModalOpen(false)
                  setContinueSecured(true)
                }}
              />
              <Button
                className={classes.PrimaryButton}
                Title='PROCEED'
                variant='subtitle1'
                BtnVariant='contained'
                color='primary'
                onClick={() => {
                  setIsModalOpen(false)
                  setContinueSecured(true)
                }}
              />
            </div>
          </div>
        </ModalContainer>
      )} */}
    </>
  )
}

export default LoanDetails
