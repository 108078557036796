import React, { useEffect, useState } from 'react'
import { Box, InputLabel, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import Input from '../Inputs/Input'
import InputWithAdornment from '../Inputs/InputWithAdornment'
import { useStyles } from './LoanCalculatorStyle'
import Recomend from '../../assets/Images/Recomend.png'
import Button from '../Button/Button'
import Radio from '@mui/material/Radio'
import { Link, useNavigate } from 'react-router-dom'
import Total from '../Inputs/Total'
import { useFormik } from 'formik'
import { loanRequest } from '../../Store/Actions/LoanRequest'
import { PromoCode } from '../../Store/Actions/PromoCode'
import { useDispatch, useSelector } from 'react-redux'
import { TooltipMessage } from './TooltipMessage'
import { InputAdornment } from '@mui/material'
import useDebounce from './useDebounce'
import InvalidPromoCode from './InvalidPromoCode'
import { SetValidPromoCode } from '../../Store/Actions/SetIsPromoValid'
import Alert from '@mui/material/Alert'
import Actions from '../../Store/Types'
import { warrantyType, warrantyTypeNames } from '../status'
import { LOANS_SUBTYPES } from '../../enums'
import { calculateAge } from '../../Utils/utils'

const LoanCalculator = ({
  amount,
  terms,
  setError,
  setErrorTerms,
  loanType,
  typeOfCollateral,
  cancellationSchemeRequested
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const loand_request = useSelector((state) => state.LoanRequestReducer)

  const [promoCode, setPromoCode] = useState(loand_request?.promoCode)
  const [promoCodeValue, setPromoCodeValue] = useState(loand_request?.promoCode)
  const [isPromoCodeValid, setIsPromoCodeValid] = useState(null)
  // const [promoCodeValue, setPromoCodeValue] = useState(
  //   loand_request.promoCode ? loand_request.promoCode : ''
  // )
  const [radioChecked, setRadioChecked] = useState(
    loand_request.debtCancellationSchemeRequested ?? null
  )
  // console.log('EL RADIO CHECK DE LOS TERMS', radioChecked)
  const [showPromoCode, setShowPromoCode] = useState(false)

  const [showModal, setShowModal] = useState(false)
  const fees = useSelector((state) => state.LoanRequestReducer.fees)

  const debouncedPromoCode = useDebounce(promoCodeValue, 4000)

  // const { promoCode: pCode } = useSelector(
  //   (state) => state?.LoanRequestReducer?.promoCode
  // )

  const promoCodeData = useSelector(
    (state) => state?.LoanRequestReducer?.promoCode
  )

  if (promoCodeData !== null && promoCodeData !== undefined) {
    const { promoCode: pCode } = promoCodeData
  } else {
    const { promoCode: pCode } = ''
  }
  const monthlyInstallment = useSelector(
    (state) => state?.LoanRequestReducer?.monthlyInstallment
  )
  const monthlyInstallmentWithPromoCode = useSelector(
    (state) => state?.LoanRequestReducer?.monthlyInstallmentWithPromoCode
  )
  const debtCancellationSchemeFee = useSelector(
    (state) => state?.LoanRequestReducer.debtCancellationSchemeFee
  )
  const totalDebtCancellationSchemeFee = useSelector(
    (state) => state?.LoanRequestReducer.totalDebtCancellationSchemeFee
  )

  const loanRate = useSelector(
    (state) => state?.LoanRequestReducer.interestRate
  )

  const isPromoValid = useSelector(
    (state) => state?.LoanRequestReducer?.isPromoValid
  )
  useEffect(() => {
    dispatch(SetValidPromoCode(true))
  }, [])

  useEffect(() => {
    if (isPromoValid !== null) {
      if (isPromoValid && isPromoValid.valid) {
        setShowModal(false)
        if (promoCode !== '') setShowPromoCode(true)
        else setShowPromoCode(false)
      } else {
        setShowModal(true)
        setShowPromoCode(false)
      }
    } else {
      setShowModal(false)
    }

    console.log(promoCode)
  }, [isPromoValid])

  useEffect(() => {
    if (amount) {
      formik.setFieldValue('requestedAmount', amount)
    } else {
      formik.setFieldValue('requestedAmount', 0)
    }
    if (terms) {
      formik.setFieldValue('requestedTerms', terms)
    } else {
      formik.setFieldValue('requestedTerms', 0)
    }

    if (loanType !== 'secure' && amount >= 1000 && terms >= 12) {
      setError('')
      setErrorTerms('')
      formik.handleSubmit()
    } else {
      if (loanType !== 'secure' && amount < 1000) {
        setError('must be greater than 1000')
      } else {
        setError('')
      }

      if (
        loanType === 'secure' &&
        typeOfCollateral === 'fully' &&
        terms > 120
      ) {
        setErrorTerms('Must be equal to or less than 120 months.')
      } else if (
        loanType === 'secure' &&
        typeOfCollateral === 'fully' &&
        terms <= 120 &&
        terms >= 12
      ) {
        setErrorTerms('')
      }
      if (
        loanType === 'secure' &&
        typeOfCollateral === 'partially' &&
        terms > 60
      ) {
        setErrorTerms('For partially secured loans, please enter or select an option that does not exceed 60 months.')
      } else if (
        loanType === 'secure' &&
        typeOfCollateral === 'partially' &&
        terms <= 60 &&
        terms >= 12
      ) {
        setErrorTerms('')
      }

      if (terms < 12) {
        setErrorTerms('Must be equal to or greater than 12 months')
      } else if (loanType !== 'secure') {
        setErrorTerms('')
      }
    }
  }, [amount, terms])

  useEffect(() => {
    if (typeof cancellationSchemeRequested === 'function') {
      cancellationSchemeRequested(radioChecked)
    }
  }, [radioChecked])

  const Monthly = monthlyInstallment

  const formik = useFormik({
    initialValues: {
      requestedAmount: amount,
      requestedTerms: terms,
      promoCode: promoCodeValue,
      interestRate: 1,
      debtCancellationSchemeRequested: radioChecked,
      warrantyTypeId:
        warrantyTypeNames.indexOf(sessionStorage.getItem('warrantyType')) + 1,
      token: sessionStorage.getItem('code')
    },
    onSubmit: (values) => {
      amount = +amount
      if (amount >= 1000 && terms > 0) {
        if (values.debtCancellationSchemeRequested !== null) {
          let formData = {
            requestedAmount: Number(amount) || 1000,
            requestedTerms: Number(terms) || 1,
            promoCode: promoCodeValue,
            loanSubTypeId: LOANS_SUBTYPES.unsecure.customer,
            interestRate: 1,
            loanTypeId: sessionStorage.getItem('loan') === 'secure' ? 2 : 1,
            debtCancellationSchemeRequested: radioChecked,
            warrantyTypeId:
              sessionStorage.getItem('loan') === 'secure'
                ? warrantyTypeNames.indexOf(
                    sessionStorage.getItem('warrantyType')
                  ) + 1
                : null,
            token: sessionStorage.getItem('code')
          }
          dispatch(loanRequest(formData, navigate))
        } else {
          let formData = {
            requestedAmount: Number(amount),
            requestedTerms: Number(terms),
            promoCode: promoCodeValue,
            loanSubTypeId: LOANS_SUBTYPES.unsecure.customer,
            interestRate: 1,
            loanTypeId: sessionStorage.getItem('loan') === 'secure' ? 2 : 1,
            debtCancellationSchemeRequested: radioChecked,
            warrantyTypeId:
              sessionStorage.getItem('loan') === 'secure'
                ? warrantyTypeNames.indexOf(
                    sessionStorage.getItem('warrantyType')
                  ) + 1
                : null,
            token: sessionStorage.getItem('code')
          }
          dispatch(loanRequest(formData, navigate))
        }
      }
    }
  })
  const handleClick = () => {
    dispatch({ type: Actions.SETISVALIDPROMOCODE, payload: { valid: true } })
    setShowModal(false)
  }

  useEffect(() => {
    if (radioChecked !== null) {
      formik.setFieldValue('debtCancellationSchemeRequested', !radioChecked)
    }

    formik.handleSubmit()
  }, [radioChecked])

  // useEffect(() => {
  //   formik.handleSubmit()
  // }, [debouncedPromoCode])

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'BZD'
  })

  const [showDCS, setShowDCS] = useState(false)

  const isEligibleForDCS = (dateOfBirth) => {
    if (!dateOfBirth) return false
    const age = calculateAge(dateOfBirth)
    return age >= 18 && age <= 65
  }

  useEffect(() => {
    setShowDCS(isEligibleForDCS(loand_request?.dateOfBirth))
  }, [loand_request])

  // console.log('EDAD 269', calculateAge(loand_request.dateOfBirth))

  return (
    <Box className={classes.LoanCalculatorContainer}>
      <Box className={classes.LoanCalculatorHeader}>
        <Typography className={classes.CalculatorHeaderText}>
          LOAN CALCULATOR
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction='column'
          spacing={{ xs: 1, sm: 2, md: 4 }}
          mt={5}
          mb={6}
        >
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
          >
            <Input
              // disabled
              className={classes.BlankInput}
              Lable='AMOUNT'
              name='requestedAmount'
              onChange={formik.handleChange}
              value={
                amount
                  ? formatter.format(amount)
                  : amount || loand_request.requestedAmount
              }
              readOnly
              disabled={true}
            />
            <Input
              // disabled
              className={classes.BlankInput}
              Lable='TERM'
              name='requestedTerms'
              onChange={formik.handleChange}
              value={
                formik.values.requestedTerms ||
                terms ||
                loand_request.requestedTerms
              }
              readOnly
              disabled={true}
            />
          </Stack>

          <Stack sx={{ width: '50%' }}>
          </Stack>

          {sessionStorage.getItem('loan') === 'secure' &&
            sessionStorage.getItem('warrantyType') === 'cash' && (
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={{ xs: 1, sm: 2 }}
                sx={{ marginTop: '11px' }}
              >
              </Stack>
            )}

          <Stack sx={{ width: '100%' }}>
            {radioChecked === true && (
              <>
                {totalDebtCancellationSchemeFee !== 0 && (
                  <Input
                    className={classes.BlankInput}
                    Lable='TOTAL DEBT CANCELLATION AMOUNT'
                    value={
                      totalDebtCancellationSchemeFee
                        ? formatter.format(totalDebtCancellationSchemeFee)
                        : totalDebtCancellationSchemeFee
                    }
                    readOnly
                    disabled={true}
                  />
                )}
              </>
            )}
          </Stack>
        </Stack>

        {showDCS && (
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2 }}
          >
            <Stack className={classes.ContainerTermsAndConditions}>
              <Link
                to={
                  loanType === 'secure'
                    ? `/Customer/MonthlyInstallments/TermsAndConditions/secure`
                    : `/Customer/yourLoanRequest/TermsAndConditions`
                } //`/Customer/yourLoanRequest/TermsAndConditions/unsecure`}
                className={classes.Links}
              >
                <Button
                  className={classes.BtnTermsAndConditions}
                  Title='TERMS AND CONDITIONS'
                  BtnVariant='contained'
                  style={{ padding: 28 }}
                />
              </Link>
              <Stack direction='row' alignItems='center' mt={1}>
                <Radio
                  checked={radioChecked}
                  onClick={(e) => {
                    setRadioChecked((current) => !current)
                  }}
                />
                <InputLabel style={{ whiteSpace: 'pre-wrap', fontSize: '1rem' }}>
                  I accept the terms and conditions
                </InputLabel>
              </Stack>
            </Stack>
            <Stack
              // width="50%"
              className={classes.recommend}
              // style={{ marginTop: '-50px' }}
            >
              <img src={Recomend} alt='' style={{ width: '301.07px' }} />
            </Stack>
          </Stack>
        )}

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2 }}
          mt={4}
        >
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            className={classes.RecomendLeftSec}
          >
            <Stack className={classes.RecomendedInputs} sx={{ width: '100%' }}>
              <Total
                className={classes.LoanFees}
                Lable='LOAN FEES'
                Text={fees || 0}
                iconTolltip={false}
              />
            </Stack>
          </Stack>

          {/* <Stack width="50%"></Stack> */}
        </Stack>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2 }}
          mt={4}
          mb={2}
        >
          <Stack direction='column' className={classes.Promo}>
            {' '}
            {/* className={classes.Promo} */}
            <Total
              className={classes.MonthlyInsurence}
              Lable='ESTIMATED MONTHLY INSTALLMENT'
              style={{ fontSize: 20 }}
              Text={Monthly}
              messageToltip={TooltipMessage.MONTHLY_INSTALLMENT}
            />
            <Box
              className={classes.EnterPromoCode}
              onClick={(values) => {
                setPromoCode((current) => !current)
              }}
            >
              <Typography>ENTER PROMO CODE</Typography>
            </Box>
          </Stack>

          <Stack direction='column' className={classes.Promo}>
            {promoCode && (
              <>
              <Input
                className={classes.PromoCode}
                Lable='PROMO CODE'
                onChange={(e) => {
                  formik.setFieldValue('promoCode', e.target.value)
                  setPromoCodeValue(e.target.value)
                }}
                value={promoCodeValue}
                name='promoCode'
              />
              {/* <Box
              className={classes.EnterPromoCode}
              onClick={handleClickPromoCode}
            >
              <Typography>VALIDATE PROMO CODE</Typography>
            </Box> */}
              </>
            )}
          </Stack>
        </Stack>

        <Stack direction='column' className={classes.Promo}>
          {monthlyInstallmentWithPromoCode ? (
            <Total
              className={classes.MonthlyInsurence}
              Lable='YOUR NEW MONTHLY INSTALLMENT'
              style={{ fontSize: 20 }}
              Text={monthlyInstallmentWithPromoCode || 0}
              messageToltip={TooltipMessage.YOUR_NEW_MONTHLY_INSTALLMENT}
            />
          ) : null}
        </Stack>
        <Alert
          icon={false}
          sx={{
            backgroundColor: '#F4EBFE',
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '18px',
            lineHeight: '25px',
            fontFamily: 'avenirMedium !important',
            marginTop: 5
          }}
        >
          The interest rate used to calculate your monthly installment is the
          Bank's base rate. This rate is subject to change based on risk. After
          the loan application is received, the interest rate will be adjusted
          accordingly, and the final rate will be presented in your final loan
          documentation.{' '}
          <strong>
            As a Belize Bank customer you participate in the loyalty program and
            enjoy lower interest rates.
          </strong>
        </Alert>
      </form>
      {showModal && <InvalidPromoCode handleClick={handleClick} />}
    </Box>
  )
}
export default LoanCalculator
