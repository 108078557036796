import axios from 'axios'

import LocalStorageService from '../Utils/LocalStoreServices'
// import { useDispatch } from 'react-redux'
import { setUser } from '../Store/context/white-list'

const setToken = async (endpoint, url, dispatch) => {
  // const dispatch = useDispatch()
  let status = false
  const sentData = {
    token: url,
  }
  await axios
    .post(endpoint, {...sentData})
    .then((response) => {
      const localStorageServices = LocalStorageService.getService()
      const token = response.data.token
      const channel = response.data.channel
      const expirationDate = response.data.expirationDate
      const userName = response.data.firstName
      dispatch(setUser(response.data))
      localStorageServices.setChannel(channel)
      localStorageServices.setExpirationDate(expirationDate)
      sessionStorage.setItem('name', userName)
      if (token) {
        localStorageServices.setToken(token)
        status = true
      }
    })
    .catch(e => console.error(e))

    return status
}

export default setToken
