import Title from '../../atoms/Title/Title'
import Button from '../../../Button/Button'
import { Grid, Typography } from '@mui/material'
import Image from '../../atoms/Image/Image'
//styles
import { useStyles } from './CurrentLoanDetailsStyles'

const CurrentLoanDetails = ({ Icon, Key, Value }) => {
  const classes = useStyles()
  return (
    <Grid
      container
      xs={12}
      md={6}
      justifyContent={'space-between'}
      alignItems={'flex-start'}
      style={{ marginTop: '24px' }}
    >
      <Grid item xs={1} md={1}>
        <Image src={Icon} />
      </Grid>
      <Grid item xs={5} md={5}>

        <Typography className={classes.CustomTypoKey}>{Key}</Typography>
      </Grid>
      <Grid item xs={5} md={5}>
      <Typography className={classes.CustomTypoValue}>{Value}</Typography>
      </Grid>
    </Grid>
  )
}

export default CurrentLoanDetails
