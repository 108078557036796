import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  rootContainer:{
    "& .css-119s0vu-MuiStack-root>:not(style)~:not(style)": {
        '@media (max-width: 900px)':{
          marginLeft: "0px !important",
        }
    }
  },
  widthVerifyBtn: {
    width: '15%',
    '@media (max-width: 900px)': {
      width: '100%'
    },
  },
  wrapFlex:{
    marginTop: '1.5rem',
    '@media (max-width: 900px)': {
      flexWrap: 'wrap',
      gap: '1rem'
    }    
  },
  container: {
    marginTop: '20px',
    background: '#56AF31',
    textAlign: 'center',
    padding: 20
  },
  MovilToast: {
    '@media (max-width: 768px)': {
      left: '50%',
      transform: 'translateX(-50%)'
    }
  },
  Header: {
    '&.MuiTypography-root': {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '29.677px',
      fontFamily: theme.fonts.family.secondary,
      color: '#ffff'
    }
  },
  HeaderSubtitle: {
    '&.MuiTypography-subtitle1': {
      fontFamily: theme.fonts.family.secondary,
      color: '#ffff',
      fontFamily: 'avenirMedium !important',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '16px',
      lineHeight: '21px'
    }
  },
  FormContainer: {
    padding: '20px',
    background: '#FFFFFF',
    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.12)',
    marginBottom: '20px'
  },
  Divider: {
    marginTop: '35px !important',
    border: '1px solid #DADADA'
  },
  Text: {
    '&.MuiTypography-subtitle1': {
      fontFamily: theme.fonts.family.secondary,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.1em',
      color: '#868686',
      marginBottom: '20px',
      marginTop: '20px'
    }
  },
  TextConfirmation: {
    '&.MuiTypography-subtitle1': {
      fontFamily: theme.fonts.family.secondary,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.1em',
      color: '#0253EC',
      marginBottom: '20px',
      marginTop: '20px'
    }
  },
  TextConfirmationError: {
    '&.MuiTypography-subtitle1': {
      fontFamily: theme.fonts.family.secondary,
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.1em',
      color: 'red',
      marginBottom: '20px',
      marginTop: '20px'
    }
  },
  MonthlyCreditInput: {
    '& .MuiInputLabel-root': {
      fontSize: '16px',
      '@media (min-width: 600px)': {
        fontSize: '19px'
      },
      fontWeight: '500',
      lineHeight: '17.76px',
      letterSpacing: '0.1em',
      color: '#706F6F',
      fontWeight: 800,
      fontFamily: theme.fonts.family.secondary
    }
  },

  UploadFiles: {
    '&.MuiButtonBase-root': {
      justifyContent: 'space-between',
      height: '38.06px',
      border: ' 1px solid #DADADA',
      color: '#B2B2B2',
      fontSize: '13px',
      fontWeight: '500',
      letterSpacing: '0.1em',
      lineHeight: '17.76px',
      fontFamily: theme.fonts.family.tertiary
    }
  },
  fileText: {
    width: '460px',
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '17.75px',
    fontFamily: theme.fonts.family.secondary,
    color: '#706F6F',
    '@media (max-width: 1200px)': {
      width: '100%'
    }
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    // width: 500,
    marginTop: 0,
    marginBottom: 15
  },
  stackNextButton:{
    alignSelf: 'flex-end',
    width: '20%',
    marginBottom: "1px",
    '@media (max-width: 900px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    }
  },
  NextButton: {
    width: '100%',
    height: 39.01,
    '&.MuiButtonBase-root': {
      backgroundColor: theme.colors.primary.Darkblue,
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: 'White',
        fontWeight: 600,
        letterSpacing: '0.2em'
      }
    },
    '@media (max-width: 900px)': {
      width: '80%',
    }
  },
  ContinueButton: {
    width: '20%',
    height: 39.01,
    '&.MuiButtonBase-root': {
      backgroundColor: theme.colors.primary.Darkblue,
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: 'White',
        fontWeight: 600,
        letterSpacing: '0.2em'
      }
    },
    '@media (max-width: 700px)': {
      width: '80%'
    }
  },
  noSSNBtn: {
    width: '100%',
    height: 39.01,
    '&.MuiButtonBase-root': {
      backgroundColor: theme.colors.primary.Darkblue,
      marginTop: 'auto',
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: 'White',
        fontWeight: 600,
        letterSpacing: '0.2em',
        '@media (max-width: 600px)': {
          width: '100%',
          marginLeft: '0% !important',
          height: 30,
          fontSize: 7
        }
      }
    }
  },
  noSSNBtnNo: {
    width: '60%',
    marginLeft: '20% !important',
    height: 39.01,
    '&.MuiButtonBase-root': {
      backgroundColor: theme.colors.primary.Darkblue,
      marginTop: 'auto',
      '&:hover': {
        backgroundColor: theme.colors.secondary.Green
      },
      '& .MuiTypography-root': {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: 'White',
        fontWeight: 600,
        letterSpacing: '0.2em'
      }
    },
    '@media (max-width: 600px)': {
      width: '100%',
      marginLeft: '0% !important',
      height: 45,
      fontSize: 12
    }
  },
  deleteFileIcon: {
    '&.MuiSvgIcon-root': {
      color: ' red',
      marginTop: 0,
      fontSize: 'large',
      marginLeft: 10,
      position: 'absolute'
    }
  },

  socialScurity: {
    '@media (max-width: 600px)': {
      flexDirection: 'column !important',
      gap: '1rem',
      '& .MuiTypography-root': {
        fontSize: '0.65rem !important'
      }
    }
  }
}))
