import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  mobielView:{
    width: '75%',
    '@media (max-width: 900px)': {
      width: '100%'
    },
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ScheduleAppointmentContainer: {
    width: "80%",
    '@media (max-width: 900px)': {
      width: '100%',
    },
  },
  ApprovedPoppup: {
    backgroundColor: "#F4EBFE",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "45px",
    '@media (max-width: 500px)': {
      padding: "16px",
    },
  },
  UploadFileText: {
    "&.MuiTypography-root": {
      marginTop: "5px",
      color: "#706F6F",
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "18px",
      fontFamily: theme.fonts.family.tertiary,
    },
  },
  deleteFileIcon: {
    "&.MuiSvgIcon-root": {
      color: " red",
      marginTop: 0,
      fontSize: "large",
      marginLeft: 10,
      position: "absolute"
    },
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    width: 500,
    marginBottom: 15
  },
  fileText: {
    width: "460px",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "17.75px",
    fontFamily: theme.fonts.family.secondary,
    color: "#706F6F",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  },
  ApprovedText: {
    "&.MuiTypography-body1": {
      width: "auto",
      marginBottom: "25.11px",
      color: "#706F6F",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "120%",
      textAlign: "center",
      fontFamily: theme.fonts.family.secondary,
    },
  },
  ApprovedPoppupImage: {
    width: "111.14px",
    height: "111.14px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffff",
    borderRadius: "50%",
    marginBottom: "17px",
    marginTop: "25.66px",
  },
  ApprovedImage: {
    width: '100px',
    filter: 'drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))',
    height: '100px'
  },
  UploadFiles: {
    "&.MuiButtonBase-root": {
      width: "100%",
      justifyContent: "space-between",
      height: "43px",
      border: " 1px solid #DADADA",
      color: "#B2B2B2",
      fontSize: "13px",
      paddingLeft: "30px",
      fontWeight: "500",
      letterSpacing: "0.1em",
      lineHeight: "17.76px",
      fontFamily: theme.fonts.family.tertiary,
    },
  },
  SubmitBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "100px",
    "& .MuiButtonBase-root": {
      width: "176px",
      height: "39px",
      backgroundColor: "#005FAA",
      color: "#ffff",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  ThankYouPoppup: {
    width: "808px",
    margin: "66px auto",
    backgroundColor: "#F2F2F2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 900px)": {
      width: "auto",
      padding: "0px 20px",
    },
  },
  ThankYouText: {
    "&.MuiTypography-body1": {
      width: "513px",
      marginBottom: "25.11px",
      color: "#706F6F",
      fontSize: "20px",
      fontWeight: "400",
      lineHeight: "120%",
      textAlign: "center",
      fontFamily: theme.fonts.family.secondary,
      "@media (max-width: 800px)": {
        width: "100%",
      },
    },
  },
  ThankYouBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "36px",
    marginTop: "20px",
    "& .MuiButtonBase-root": {
      width: "176px",
      height: "39px",
      backgroundColor: "#005FAA",
      color: "#ffff",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  Link: {
    textDecoration: "none",
  },
  LoanCalculatorHeader: {
    height: "36.52px",
    backgroundColor: theme.colors.secondary.Green,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
    borderRadius: 6
  },
  CalculatorHeaderText: {
    "&.MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "600",
      color: "#ffff",
      lineHeight: "16px",
      letterSpacing: "0.2em",
    },
  },
  Accordian: {
    marginBottom: "15px",
    "& .MuiAutocomplete-input": {
      textAlign: "center",
      fontSize: "13px",
      lineHeight: "17.76px",
      letterSpacing: "0.1em",
      fontWeight: "500",
      fontFamily: theme.fonts.family.tertiary,
    },
    "& .MuiAccordionSummary-root ": {
      background: "#005FAA",
      color: 'white',
      borderRadius: 6,
      
    },
    "& .MuiTypography-body1": {

      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '16px',
      lineHeight: '22px',
      letterSpacing: '0.1em',
      marginBottom: '20px',
      fontFamily: theme.fonts.family.secondary,
    },
    "& .MuiAccordionSummary-content": {
      placeContent: "center",
    },
    "& .MuiAccordionDetails-root": {
      color: "unset",
      padding: "35px",
    },
    "& .MuiSvgIcon-root": {
      fill: 'white',
    },
    "& .MuiCollapse-entered": {
      backgroundColor: theme.colors.secondary.blue
    }
  },
  RadioBtn: {
    width: "49%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
    "& .MuiSvgIcon-root": {
      fill: `${theme.colors.secondary.blue} !important`,
    },
  },
  RadioLabel: {
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 13,
    fontWeight: "500",
    color: theme.colors.primary.Grey,
  },
  pdf: {
    width: "40px",
    height: "40px",
  },
  fileText: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '25px',
    fontFamily: theme.fonts.family.primary
  },
  NextButton: {
    width: "20%",
    height: 39.01,
    "&.MuiButtonBase-root": {
      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        color: "White",
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
    },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  Alert: {
    backgroundColor: "#D3E4F6",
    marginBottom: '20px'
  },
  ThankYouBtn: {
    "&.MuiButtonBase-root": {
      color: '#ffff',
      width: '155px',
      height: '34px',
      marginTop: '10px',
      backgroundColor: '#005FAA',
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "10px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  errorMessage: {
    color: "red",
    margin: 0,
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 14,
    fontWeight: 600
  },
}))
